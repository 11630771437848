import React, { useState } from 'react';
import { Box, FormControlLabel, Grid, Stack, Typography, FormControl, TextField, Avatar, Tooltip, TooltipProps, tooltipClasses, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FieldControl, FieldGroup } from 'react-reactive-form';
import { NavLink, useNavigate } from 'react-router-dom';
import { cityOptions, notificationOptions, stateOptions } from '../../services/components/selectOptions.service';
import { GuestCheckOutTwoHandler, PatientSignUpThreeForm, PatientSignUpOneHandler } from '../../services/auth/register.service';
import { useAppDispatch, useAppSelector } from '../../store';

import { AxiosResponse } from 'axios';
import { CheckInput } from '../../core/forms/inputs/CheckInput';
import { GoogleAutoCompleteInput } from '../../core/forms/inputs/GoogleAutoCompleteInput';
import { InputAddress } from '../../core/forms/inputs/InputAddress';
import { InputEmail } from '../../core/forms/InputEmail';
import InputPassword from '../../core/forms/inputs/InputPassword';
import { InputPhone } from '../../core/forms/inputs/InputPhone';
import { InputSelect } from '../../core/forms/inputs/InputSelect';
import { InputText } from '../../core/forms/inputs/InputText';
import Patient from '../../constants/grx-api';
import PrimaryButton from '../../core/buttons/primary-button';
import TertiaryButton from '../../core/buttons/tertiary-button';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { User } from '../../models/User';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormArray, FormControl as FormControlType, FormGroup, Validators } from 'react-reactive-form';
import { Color } from '../../interfaces/Color';
import moment from 'moment';
import { RadioInput } from '../../core/forms/inputs/RadioInput';
import { AllergyAutoCompleteInput } from '../../core/forms/inputs/AllergyAutoCompleteInput';
import { InputTextCC } from '../../core/forms/InputTextCC';
import { InputExpiryDate } from '../../core/forms/InputExpiryDate';
import { InputCvv } from '../../core/forms/InputCvv';
import { InputZipCode } from '../../core/forms/inputs/InputZipCode';
import { GET_ALLERGIES } from '../../constants/Endpoints';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { genderOptions } from '../../services/components/selectOptions.service';
import { profileActions } from '../../store/Actions';
import PatientWithoutToken from '../../constants/grx-api-tokenless';
import { InputDate } from '../../core/forms/inputDate';
import { InputDateOfBirth } from '../../core/forms/inputs/InputDateOfBirth';
import SujaImg from '../../assets/suja.png';
import axios from 'axios';
import { debounce } from '@mui/material/utils';
import { PatientLoginFormPassword, PatientLoginFormPasswordHandler } from '../../services/auth/login.service';
import EmailInputIcon from '../../core/forms/inputs/EmailInputIcon';
import PasswordInputIcon from '../../core/forms/inputs/PasswordInputIcon';
interface CustomTooltipProps extends TooltipProps {
    open?: boolean;
}

const CustomWidthTooltip = styled(({ className, ...props }: CustomTooltipProps) => <Tooltip {...props} classes={{ popper: className }} open={true} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500
    }
});


const GuestSignup = React.memo((props: { handleTabClick: (res?: number) => void }) => {
    const navigate = useNavigate();

    const [selectedForm, setSelectedForm] = useState<string>('password');
    const [openCustomer, setOpenCustomer] = useState<boolean>(false);
    const [allergyOptions, setAllergyOptions] = useState<any>([]);
    const dispatch = useAppDispatch();

    const patientFormSubmit = async () => {
        const data = await GuestCheckOutTwoHandler(navigate);
        if (data === 201) {
            props.handleTabClick(1)
            // dispatch(profileActions.setUserData({ user: data as User }));
        }
    }

    const onDateChange = (date: Date) => {
        console.log('Date Picker Date', moment.utc(date));
        PatientSignUpThreeForm.patchValue({
            dateOfBirth: date
        });
        // if (e && e.$d && e.$d !== null) {
        //   PatientSignUpThreeForm.patchValue({
        //     dateOfBirth: e.$d
        //   });
        // } else {
        //   PatientSignUpThreeForm.patchValue({
        //     dateOfBirth: ''
        //   });
        // }
    };


    const handlePasswordLogin = async () => {
        const data = await PatientLoginFormPasswordHandler(navigate);
        if (data === 450) {
            setOpenCustomer(true);
            props.handleTabClick(2)

        } else {
            if (data) {
                props.handleTabClick(2)
                dispatch(profileActions.setUserData({ user: data as User }));
            }
        }
    };

    // update form validation
    let isValidationUpdateInProgress = false;

    const updateValidation = () => {
        if (isValidationUpdateInProgress) {
            return;
        }

        isValidationUpdateInProgress = true;
    }

    // update allergy validation

    const handleAutoCompleteChangeShipping = ({ address1, locality, short_name, postcode, country }: { address1: string; locality: string; short_name: string; postcode: string; country: string }) => {

        console.log(address1, locality, short_name, postcode.split('-')[0])

        PatientSignUpThreeForm.patchValue({
            street: address1,
            city: locality,
            state: short_name,
            zip_code: postcode.split('-')[0],
            country: country
        });
    };


    useEffect(() => {
        PatientSignUpThreeForm.valueChanges.subscribe(() => {
            updateValidation();
        });
        return () => {
            PatientSignUpThreeForm.valueChanges.unsubscribe(() => null);
        };
    }, []);


    return (
        <Box sx={{ height: 'auto' }} p={4}>
            <FieldGroup
                control={PatientSignUpThreeForm}
                render={({ get, invalid }) => (
                    <form>
                        <Container>
                            <Grid container>

                                <Grid item xs={12} lg={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={12} mb={2}>
                                            <Typography className="info">Personal Info</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <FieldControl name="firstName" render={InputText} meta={{ id: 'signup1_first_name', name: 'firstName', value: 'firstName', helperText: 'Patient First Name is Required', label: 'First Name', placeholder: 'Please Enter Patient First Name', autoComplete: false }} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <FieldControl name="middleName" render={InputText} meta={{ id: 'signup1_middle_name', name: 'middleName', value: 'middleName', label: 'Middle Name', placeholder: 'Please Enter Patient First Name', required: false, autoComplete: false }} />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <FieldControl name="lastName" render={InputText} meta={{ id: 'signup1_last_name', name: 'lastName', value: 'lastName', helperText: 'Patient Last Name is Required', label: 'Last Name', placeholder: 'Please Enter Patient Last Name', autoComplete: false }} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <FieldControl name="gender" render={InputSelect} meta={{ name: 'gender', value: 'gender', options: genderOptions, label: 'Gender', placeholder: 'Please Enter Gender' }} />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <FieldControl
                                                name="dateOfBirth"
                                                render={InputDateOfBirth}
                                                meta={{
                                                    name: 'dateOfBirth',
                                                    value: get('dateOfBirth')?.value ? get('dateOfBirth').value : '',
                                                    label: 'Date of Birth',
                                                    placeholder: 'Please Enter Date of Birth',
                                                    required: true,
                                                    onChange: onDateChange,
                                                    helperText: PatientSignUpThreeForm?.get('dateOfBirth').errors?.required ? 'DOB is Required' : PatientSignUpThreeForm.get('dateOfBirth').errors?.pattern ? 'Please enter valid DOB' : ''
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <FieldControl name="phone" render={InputPhone} meta={{ name: 'phone', value: 'phone', helperText: 'Cell Phone Number is Required', label: 'Cell Phone Number', placeholder: 'Please Enter Cell Phone Number', autoComplete: false }} />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <FieldControl name="email" render={InputEmail} meta={{ id: 'login_email', name: 'Email', value: 'email', helperText: 'Email is Required.', label: 'Email', placeholder: 'Please Enter Email', autoComplete: false }} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <FieldControl name="zip_code" render={InputAddress} meta={{ name: 'code', helperText: 'Postal Code is Required', label: 'Postal Code', placeholder: 'Please Enter Postal Code', required: true }} />
                                        </Grid>




                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container mt={2} pb={4}>
                                <Grid item xs={0} lg={6} mb={2}></Grid>
                                <Grid item xs={12} sm={12} md={5} lg={6} xl={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Box className="mandatory">
                                        <span>*</span> This field is mandatory
                                    </Box>
                                </Grid>
                                <Grid container item xs={12} lg={12} spacing={2}>
                                    <Grid container item display="flex" justifyContent="flex-end" mt={3} sx={{ width: '100%' }}>

                                        <Grid container item mt={1} xs={12} sm={12} md={12} lg={6} xl={6} p={0} sx={{ display: 'flex', direction: 'column', justifyContent: 'flex-end' }} spacing={6}>
                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <Box>
                                                    <NavLink to="/">
                                                        <TertiaryButton label={'Cancel'} />
                                                    </NavLink>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <Box>
                                                    <PrimaryButton label={'Next'} onClick={patientFormSubmit} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </form>)} />

        </Box>
    );
});

export default GuestSignup;
