import { Box, Button, Container, DialogContent, Grid, Stack, Typography } from '@mui/material';
import { FieldControl, FieldGroup, Validators } from 'react-reactive-form';

import { GoogleAutoCompleteInput } from '../../../core/forms/inputs/GoogleAutoCompleteInput';
import { InputAddress } from '../../../core/forms/inputs/InputAddress';
import { InputCvv } from '../../../core/forms/InputCvv';
import { InputExpiryDate } from '../../../core/forms/InputExpiryDate';
import { InputText } from '../../../core/forms/inputs/InputText';
import { InputTextCC } from '../../../core/forms/InputTextCC';
import { InputZipCode } from '../../../core/forms/inputs/InputZipCode';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cityOptions, stateOptions } from '../../../services/components/selectOptions.service';
import { RootState, useAppDispatch, useAppSelector } from '../../../store';
import { User } from '../../../models/User';
import { AddNewCardWithoutLoadFormHandler, AddNewCardWithoutLoadFormHandlerAsGuest, addNewCardForm } from '../../../services/pages/popup-form/AddNewCardForRequestRefill.service';

export const AddNewCard = (props: { handleClose: (e?: React.SyntheticEvent | string) => void; openPayInfo: (res?: boolean) => void; skipButton?: boolean; orderId: Number }) => {
  const router = useNavigate();
  const user: User = useAppSelector((state: RootState) => state.profileReducer.user);
  const [mask, setMask] = useState('9999');

  
  let orders = JSON.parse(localStorage.getItem('Orders') || '[]');


  // Check if the order exists in the local storage by orderId
  const orderIndex = orders.orders.findIndex((order: any) => order.order.orderId === props.orderId);

  const handleCard = async (e: React.SyntheticEvent) => {
    addNewCardForm.patchValue({
      pat_id: orders.orders[orderIndex].patient.patientId
    });
    const res = await AddNewCardWithoutLoadFormHandlerAsGuest(e, router);
    // console.log('RES::', res);
      if (res === 'OK') {
          props.openPayInfo()
          handleFormClose(res)
      };
  };

    const handleFormClose = (res: any) => {
        props.openPayInfo()
        props.handleClose(res);
  };

  const handleAutoCompleteChange = ({ address1, locality, short_name, postcode, country }: { address1: string; locality: string; short_name: string; postcode: string; country: string }) => {
    addNewCardForm.patchValue({
      street: address1,
      city: locality,
      state: short_name,
      billingZc: postcode.split('-')[0],
      country: country
    });
  };

  let isUpdateMask = false;

  const updateCvvMask = () => {
    if (isUpdateMask) {
      return;
    }

    isUpdateMask = true;
    if (addNewCardForm.get('cc_number').value) {
      let cardDigits: string = String(addNewCardForm.get('cc_number').value.replace(/\D/g, ''));
      if (cardDigits?.length === 0) {
        addNewCardForm.patchValue({
          cvc: ''
        });
      }
      if (cardDigits && cardDigits.charAt(0) === '3' && ['4', '7'].includes(cardDigits.charAt(1))) {
        setMask('9999');
      } else {
        setMask('999');
      }
    }

    isUpdateMask = false;
  };

  useEffect(() => {
    addNewCardForm.reset();
    addNewCardForm.patchValue({
      country: 'US'
    });
    addNewCardForm.valueChanges.subscribe(() => {
      updateCvvMask();
    });

    return () => {
      addNewCardForm.valueChanges.unsubscribe(() => null);
    };
  }, []);

  return (
    <>
      <DialogContent dividers className="popup_content" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box component="main" className="card-info">
          <Container maxWidth="lg">
            <Box className="main-box">
              <Box sx={{ bgcolor: 'background.paper' }}>
                <Stack className="modal_heading_main" direction="row" justifyContent="center" alignItems="center">
                  <Typography className="heading_bottom_without_border">Add New Payment Method</Typography>
                </Stack>
                <FieldGroup
                  control={addNewCardForm}
                  render={({ get, invalid }) => (
                    <form>
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Stack className="inputs_fields_ratio" direction="row">
                              <Grid xs={12}>
                                <FieldControl name="cc_number" render={InputTextCC} meta={{ name: 'cc_number', label: 'Card Number', placeholder: 'Please Enter Card Number', helperText: 'Card Number is required' }} />
                              </Grid>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <FieldControl name="exp_date" render={InputExpiryDate} meta={{ helperText: 'Exp. Date is required.', label: 'Exp. Date', name: 'exp_date', placeholder: 'Please Enter Exp Date', value: 'exp_date' }} />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Stack className="inputs_fields_ratio" direction="row">
                              <Grid xs={12}>
                                {mask === '999' && (
                                  <FieldControl
                                    name="cvc"
                                    render={InputCvv}
                                    meta={{
                                      name: 'cvc',
                                      helperText: 'CVC/CVV is required',
                                      label: 'CVC/CVV',
                                      placeholder: 'Please Enter CVC/CVV',
                                      mask: '999'
                                    }}
                                  />
                                )}
                                {mask === '9999' && (
                                  <FieldControl
                                    name="cvc"
                                    render={InputCvv}
                                    meta={{
                                      name: 'cvc',
                                      helperText: 'CVC/CVV is required',
                                      label: 'CVC/CVV',
                                      placeholder: 'Please Enter CVC/CVV',
                                      mask: '9999'
                                    }}
                                  />
                                )}
                              </Grid>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Stack className="inputs_fields_ratio" direction="row">
                              <Grid xs={12}>
                                <FieldControl
                                  name="cardholdername"
                                  render={InputText}
                                  meta={{
                                    name: 'cardholdername',
                                    helperText: 'Cardholder Name is required.',
                                    label: 'Cardholder Name',
                                    placeholder: 'Please Enter Cardholder Name'
                                  }}
                                />
                              </Grid>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={12} lg={12} className="google-auto-complete-container" style={{ zIndex: 9999 }}>
                            <GoogleAutoCompleteInput uniqueKey={'patient-address-auto-complete'} handleAutoCompleteChange={handleAutoCompleteChange} required={true} />
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <FieldControl name="apt_suite" render={InputAddress} meta={{ id: 'pat_apt_suite', name: 'apt_suite', helperText: 'Apt./Suite is required', label: 'Apt./Suite', placeholder: 'Please Enter Apt./Suite' }} />
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <FieldControl name="city" render={InputAddress} meta={{ id: 'pat_city', name: 'city', helperText: 'City is Required', options: cityOptions, label: 'City', placeholder: 'Please Enter City', required: true }} />
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <FieldControl name="state" render={InputAddress} meta={{ id: 'pat_state', name: 'state', helperText: 'State is Required', options: stateOptions, label: 'State', placeholder: 'Please Enter State', required: true }} />
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <FieldControl name="billingZc" render={InputZipCode} meta={{ id: 'pat_billing_zc', name: 'Postal Code', helperText: 'Postal Code is required', label: 'Postal Code', placeholder: 'Please Enter Postal Code', required: true }} />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12} mt={3}>
                            <Stack className="add_btnouter" direction="row" justifyContent="center" alignItems="center" gap={1.5}>
                              <Box className="add_outerbtnscancel" onClick={props.handleClose}>
                                <Button
                                  className="add_cancel_btn"
                                  variant="outlined"
                                  style={{
                                    color: '#032439',
                                    fontSize: '16px',
                                    fontWeight: '600',
                                    backgroundColor: '#fff',
                                    border: '1px solid #00ACBA',
                                    borderRadius: '8px',
                                    boxShadow: 'none',
                                    textTransform: 'capitalize',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    minWidth: '146px',
                                    height: '49px'
                                  }}
                                >
                                  {props.skipButton ? 'Skip' : 'Cancel'}
                                </Button>
                              </Box>
                              <Box className="add_outerbtns">
                                <Button
                                  className="cancel_btn"
                                  variant="contained"
                                  onClick={handleCard}
                                  // disabled={invalid}
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: '600',
                                    color: '#ffffff',
                                    backgroundColor: '#00ACBA',
                                    border: '1px solid #00ACBA',
                                    borderRadius: '8px',
                                    boxShadow: 'none',
                                    textTransform: 'capitalize',
                                    flex: 1,
                                    width: '100%',
                                    height: '49px'
                                  }}
                                >
                                  Add
                                </Button>
                              </Box>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    </form>
                  )}
                />
              </Box>
            </Box>
          </Container>
        </Box>
      </DialogContent>
    </>
  );
};
