import { Box, Container, Grid, Stack, Typography, Button, AppBar, Tab, Tabs, useTheme, Divider, FormControlLabel, Radio, IconButton } from '@mui/material';
import { GET_PATIENT_ORDERS, GET_PATIENT_SHIPPING_ADDRESS, PATIENT_PROFILE_IMAGE_CHANGE, UPDATE_ORDER_SHIPPING_ADDRESS } from '../../constants/Endpoints';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState, ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AxiosResponse } from 'axios';
import { BootstrapDialog, StyledTableCell, StyledTableRow } from '../../core/tables/tableStyles';
import Carousel from 'react-material-ui-carousel';
import Chat from '../../assets/icons/chat.svg';
import Patient from '../../constants/grx-api';
import Left from '../../../assets/icons/left_arrow.svg';
import { OrderTable } from '../../components/tables/OrderTable';
import Right from '../../../assets/icons/right_arrow.svg';
import SecondaryButton from '../../core/buttons/secondary-button';
import { User } from '../../models/User';
import { dashboardActions } from '../../store/Actions';
import { isLoggedIn } from '../../services/auth/auth.service';
import { toast } from 'react-toastify';
import Footer from '../../components/footer/footer';
import cameraPic from '../../../assets/icons/camera.svg';
import { profileActions } from '../../store/Actions';
import { Stepper, Step } from 'react-form-stepper';
import noteImg from '../../../assets/icons/prescription.svg';
import veclImg from '../../../assets/icons/Layer_1.svg';
import delImg from '../../../assets/icons/mailbox.svg';
import Edit from '../../assets/icons/deleat_edit.svg';
import pendingPayment from '../../../assets/icons/pending-payment.svg';
import preparing from '../../../assets/icons/preparing.svg';
import DoneIcon from '@mui/icons-material/Done';
// import { Order } from '../../models/Order';
import moment from 'moment';
import { AddNewCard } from '../../components/popup/AddNewCard';
import { formatMessage } from '../../constants/util';
import { addNewShippingAddressForm } from '../../services/pages/popup-form/AddNewShippingAddressForm.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { AutoEnrollRefill } from '../../components/popup/EnrollInAutoRefill';
import PayInfo from '../../components/popup/DeliveryPaymentGuestInfo';
import PrimaryButton from '../../core/buttons/primary-button';
import { OrderInterface } from '../../interfaces/order';
import { ArrowBack } from '@mui/icons-material';
import { FieldControl, FieldGroup } from 'react-reactive-form';
import { AllergyAutoCompleteInput } from '../../core/forms/inputs/AllergyAutoCompleteInput';
import { allergiesForm, AllergiesUpdateHandler, personalUserForm, settingUpdateUserProfileHandler } from '../../services/pages/settings/personalLoginForm';
import { EditGuestOrderShippingAddress } from '../../components/popup/EditGuestShippingAddress';
import { UpdateGuestOrderDeliveryDate } from '../../components/popup/updateGuestOrderDate';
import { EditAllergiesAsGuest } from '../../components/popup/AllergiesUpdateAsGuest';
import { OrderDeliveryDate } from '../../components/popup/AddExpectedDateRequestRefillAsGuest';
import { MessagePopup } from '../../components/popup/MessagePopup';
import { RequestRefill } from '../../components/popup/RequestRefillGuestAddress';
import PayRefillInfo from '../../components/popup/DeliveryPaymentInfoRequestRefillAsGuest';
import { RequestRefillDetail } from '../../components/popup/RequestRefillDetailAsGuest';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);

interface ShippingAddress {
    address1: string;
    address2: string | null;
    city: string;
    state: string;
    zipCode: string;
}

interface Prescription {
    name: string;
    price: number;
    originalRefill: number;
    refillLeft: number;
    refillUsed: number;
}

interface Doctor {
    firstName: string;
    lastName: string;
}

interface Patient {
    firstName: string;
    patientId: number;
}

interface Order {
    order: {
        orderId: number;
        invoiceNumber: string;
        totalPrice: number;
        expectedByDate: string;
    };
    shippingAddress: ShippingAddress;
    prescriptions: Prescription[];
    doctor: Doctor;
    patient: Patient;
}

interface BillingAddress {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
}

interface Card {
    lastFourDigits: string;
    expiration: string;
    cardId: string;
    default: boolean;
    billingAddress: BillingAddress;
    type: string;
}

interface Allergy {
    ptalr_id: number;
    pat_id: number;
    name: string;
    hicl_seqno: number | null;
    hic: number | null;
    dam_agcsp: number;
    symptom: string | null;
    symptom_id: number | null;
}

interface doctor {
    firstName: string | null
    lastName: string | null
}

interface OrderDataType {
    orders: Order[];
    cards: Card[];
    allergies: Allergy[];
    doctor: doctor[];
}

interface ShippingAddress {
    address1: string;
    address2: string | null;
    city: string;
    state: string;
    zipCode: string;
}



export default function DashboardPage(props: { handleTabClick: (res?: number) => void; }) {
    const router = useNavigate();
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const [isLoaded, setIsLoaded] = useState<boolean>(true);
    const [medication, setMedication] = useState<boolean>(false);
    const [model, setModel] = useState<{ requestRefill: boolean; editAddress: boolean; updateDeliveryDate: boolean; editAllergy: boolean, payInfo: boolean, orderDate: boolean, message: boolean, payRefillInfo: boolean, requestRefillDetail: boolean }>({ requestRefill: false, requestRefillDetail: false, editAllergy: false, editAddress: false, orderDate: false, message: false, updateDeliveryDate: false, payInfo: false, payRefillInfo: false });

    const unpaidOrders: Order[] = useAppSelector((state) => state.dashboardReducer.unpaidOrders);
    const orders: Order[] = useAppSelector((state) => state.dashboardReducer.orders);
    const recentOrders: Order[] = useAppSelector((state) => state.dashboardReducer.recentOrders);
    const [unpaidInvoiceTabIndex, setUnpaidInvoiceTabIndex] = useState<number>(0);
    const [shippedOrderTabIndex, setShippedOrderTabIndex] = useState<number>(0);
    const [orderId, setOrderId] = useState<string | number>(''); // to update shipping address and expected date
    const [orderExpectedDate, setOrderExpectedDate] = useState<Date | string | null>(null); // to update expected date
    const [selectedDate, setSelectedDate] = useState<String>("");

    // temp fix
    // const [shippedOrders, setShippedOrders] = useState<Order[]>(dummyOrders);
    const user: User = useAppSelector((state) => state.profileReducer.user);

    const [enrollmentStates, setEnrollmentStates] = useState<number>();
    const [unEnrollmentStates, setUnEnrollmentStates] = useState<{ [key: string]: boolean }>({});

    const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({});
    const [totalPrice, setTotalPrice] = useState<string>();
    const [OrderData, setOrderData] = useState<OrderDataType>({
        orders: [],
        cards: [],
        allergies: [],
        doctor: []
    });
    const [payOrderData, setPayOrderData] = useState<OrderInterface | undefined>(undefined);
    const [unEnrolledIds, setUnEnrolledIds] = useState<number[]>([]);
    const [originalShippingAddress, setOriginalShippingAddress] = useState<ShippingAddress>({
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: ""
    });;
    const [rxId, setRxId] = useState<number[]>([]);
    

    type IdStates = {
        [key: string]: boolean; // or [key: number]: boolean if keys are numbers
    };

    const getIdsWithTrueValue = (states: IdStates): string[] => {
        return Object.entries(states)
            .filter(([id, value]) => value === true) // Filter for true values
            .map(([id]) => id); // Map to get only the IDs
    };
    
    useEffect(() => {
        sessionStorage.setItem("AutoRefill",JSON.stringify(trueIds))
    })

    // Example usage
    const trueIds = getIdsWithTrueValue(unEnrollmentStates);
    console.log(trueIds); // Output: ["123", "789"]
    console.log(unEnrollmentStates); // Output: ["123", "789"]

    const unEnrollHandler = (prescriptionId: number) => {
        const newState = !unEnrollmentStates[prescriptionId];
        // Send rxId with the new state (true/false)

        // Update the enrollment state
        setUnEnrollmentStates({
            ...unEnrollmentStates,
            [prescriptionId]: newState
        });

        if (newState) {
            setUnEnrolledIds((prevIds) => [...prevIds, prescriptionId]);
        } else {
            setUnEnrolledIds((prevIds) => prevIds.filter((id) => id !== prescriptionId));
        }
    };

    useEffect(() => {
        if (orders.length == 1) {
            setEnrollmentStates(Number(orders[0].order.invoiceNumber))
            setTotalPrice(orders[0].order.totalPrice.toString())
        }
    }, [orders])

    const handleEditAllergy = (order: Order) => {

        setModel(prevModel => ({
            ...prevModel,
            editAllergy: true
        }));
    };


    const handleRequestRefill = (order: Order) => {
        setOrderShippingAddress(order as Order);

        setModel(prevModel => ({
            ...prevModel,
            requestRefill: true
        }));
    };

    const handleRequestRefillClose = (res?: boolean) => {
        if (res) {
            getOrders();
        }
        closeModel();
    };
    const redirectToPrescriptions = () => {
        router('/home/prescriptions');
    };

    const handleProductTour = () => {
        window.open('https://prescribe.imprimisrx.com/home/dashboard?product_tour_id=443253', '_self');
    };

    const handleUnpaidInvoiceTabChange = (event: React.SyntheticEvent, newValue: number) => {
        event.preventDefault();
        setUnpaidInvoiceTabIndex(newValue);
    };

    const handleOnOrderChange = (event: React.SyntheticEvent, newValue: number) => {
        event.preventDefault();
        setShippedOrderTabIndex(newValue);
    };


    const closeModel = () => {
        setModel((prev) => ({ ...prev, requestRefillDetail: false, orderDate: false, payRefillInfo: false, editAddress: false, updateDeliveryDate: false, message: false, editAllergy: false, requestRefill: false, payInfo: false }));
    };

    const handleBillingModelOpen = () => {
        const billingPopupFlag = parseInt(localStorage.getItem('billingPopup') as string);
        const hasDefaultBillingCard = user?.hasDefaultBillingCard;
        if (billingPopupFlag === 0) {
            if (!hasDefaultBillingCard) {
                setModel((prev) => ({ ...prev, billingPopup: !hasDefaultBillingCard }));
            }
        }
    };

    const handleBillingModelClose = (e?: string | React.SyntheticEvent) => {
        localStorage.setItem('billingPopup', '1');
        if (e === 'OK') {
            // As the user has cardsnow, so set the 'hasDefaultBillingCard' to true
            dispatch(profileActions.setUserData({ user: User.create({ ...user, hasDefaultBillingCard: true }, true) as User }));
        }
        closeModel();
    };

    const setOrderShippingAddress = (order: Order) => {
        addNewShippingAddressForm.patchValue({
            street: order.shippingAddress.address1,
            apt_suite: order.shippingAddress.address2,
            city: order.shippingAddress.city,
            state: order.shippingAddress.state,
            code: order.shippingAddress.zipCode,
            address: order.shippingAddress.address1
        });
    };

    const handleEditOrderAddress = (order: Order) => {
        setOrderShippingAddress(order as Order);
        setModel((oldData) => ({
            ...oldData,
            editAddress: true
        }));
    };

    const handleEditOrderAddressClose = (res?: boolean) => {
        if (res) {
            getOrders();
        }
        closeModel();
    };

    const handleUpdateOrderDeliveryDate = (order: Order) => {
        setOrderId(order?.order?.orderId);
        let expectedDate: any = null;
        let dateAfterFourDays = dayjs.utc(new Date()).add(8, 'days');
        let orderExpectedDate = dayjs.utc(order.order.expectedByDate);
        if (orderExpectedDate.isAfter(dateAfterFourDays) || orderExpectedDate.isSame(dateAfterFourDays)) {
            expectedDate = orderExpectedDate.format('YYYY-MM-DD');
        } else {
            expectedDate = dateAfterFourDays.format('YYYY-MM-DD');
        }

        setOrderExpectedDate(expectedDate);
        setModel((oldData) => ({
            ...oldData,
            updateDeliveryDate: true
        }));
    };

    const formattedExpectedDate = (order: Order) => {
        // Retrieve OrderDate from localStorage
        const storedOrderDate = localStorage.getItem("OrderDate");
        let expectedDate: any = null;
      
        // Check if there's an OrderDate in localStorage and parse it
        if (storedOrderDate) {
          expectedDate = dayjs.utc(JSON.parse(storedOrderDate));
        } else {
          // Set the date 7 days after the current day if no OrderDate is present
          let dateAfterSevenDays = dayjs.utc(new Date()).add(8, 'days');
          expectedDate = dateAfterSevenDays;
        }
      
        // Convert to the browser's local time
        const localDateTime = expectedDate?.local();
      
        // Extract date components
        const date = localDateTime.format('Do');
        const month = localDateTime.format('MMM'); // Month name
        const year = localDateTime.format('YYYY');
      
        // Return formatted date string
        return `${month} ${date}, ${year}`;
      };
      

    const handleEnrollAutoRefillClose = (res?: boolean) => {
        if (res) {
            getOrders();
        }
        closeModel();
    };

    const handleUpdateOrderDeliveryDateClose = (res?: boolean) => {
        if (res) {
            getOrders();
        }
        closeModel();
    };

    // ------------ API CALLS -------------- //

    // orders
    const getOrders = async (offset = 0, count = 1000) => {
        /*     try {
                const res: AxiosResponse = await Patient.get(GET_PATIENT_ORDERS, {
                    params: { offset, count }
                });
                if (res.status === 200 && Array.isArray(res.data) && res.data.length > 0) {
                    dispatch(dashboardActions.setOrders({ data: res.data }));
                    // dispatch(dashboardActions.setShippedOrders({ data: res.data }));
                    // dispatch(dashboardActions.setUnpaidOrders({ data: res.data })); // temp-fix
                }
            } catch (err: any) {
                if (err?.response?.data?.message) {
                    toast(formatMessage(err?.response.data.message));
                    return;
                }
            } */
        /*      const storedOrders = localStorage.getItem("Orders");
             const getOrder = storedOrders ? JSON.parse(storedOrders) : [];
             setOrders(getOrder);
 
             console.log(OrderData,getOrder) */

    };
    useEffect(() => {
        // Fetch the orders from localStorage inside the useEffect hook
        const storedOrders = localStorage.getItem("Orders");
        const getOrder = storedOrders ? JSON.parse(storedOrders) : [];

        // Update state with the parsed orders
        setOrderData(getOrder);

        // Log the value directly from getOrder, not OrderData (which may still be the initial value)
    }, [model.editAddress,model.editAllergy]); // The empty dependency array means this effect runs only once when the component mounts

    // Log the value of OrderData after it has been set
    useEffect(() => {
        console.log('OrderData:', OrderData);
    }, [OrderData]); // This runs whenever OrderData is updated

    const logoutHandler = async () => {
        try {
            localStorage.removeItem('Orders');
            localStorage.removeItem('tab');
            localStorage.clear();
            //  window.location.reload();
            //   router('/');
            props.handleTabClick(0)
        } catch (err) {
            console.error(err);
            toast(formatMessage('Unable to remove details from device storage'));
        }
    };



    useEffect(() => {
        if (user) {

            // onload api calls
            getOrders();
            handleBillingModelOpen();

        }
    }, [dispatch, user]);

    const handelPayModel = () => {
        setModel((oldData) => ({
            ...oldData,
            payInfo: true
        }));
    }



    const enrollHandler = (order: OrderInterface) => {
        setPayOrderData(order);
        setOrderId(order.order.orderId)
        const invoiceNumber = parseFloat(order.order.invoiceNumber);
        setEnrollmentStates(isNaN(invoiceNumber) ? undefined : invoiceNumber);
        setTotalPrice(order.order.totalPrice.toString());

    };

    const handlePayClose = () => {
        closeModel()
    }

    const onSelectAllergies = (data: { value: string }[]) => {
        const value = data.map((item) => item).join();
        allergiesForm.patchValue({
            allergies: value
        });
    };

    // const settingPersonalInfoHandler = async (e?: React.SyntheticEvent) => {
    //     const res: any = await AllergiesUpdateHandler(e as React.SyntheticEvent, user);
    //     if (res?.status === 201) {
    //         //   dispatch(profileActions.setUserData({ user: User.create(res.data, true) as User }));
    //         // navigate('/home/settings');
    //     }
    // };

    const [isEditing, setIsEditing] = useState(false);

    // Toggle edit/save mode
    // const toggleEdit = () => {

    //     console.log("jij")

    //     if (isEditing) {
    //         settingPersonalInfoHandler(); // Call save function when saving
    //     }
    //     setIsEditing(!isEditing);
    // };

    const handleDeliveryDate = (res: Array<number>) => {

        setUnEnrolledIds(res)

        setModel((oldData) => ({
            ...oldData,
            orderDate: true, requestRefill: false
        }));
    }

    const handleOpenPay = () => {
        let orders = JSON.parse(localStorage.getItem('Orders') || '[]');
        const orderIndex = orders.orders.findIndex((order: any) => order.order.orderId === orderId);

        setOriginalShippingAddress(orders.orders[orderIndex].shippingAddress)
        setModel((oldData) => ({
            ...oldData,
            payRefillInfo: true,
        }));
    }

    const handleOpenDeliveryAddress = (res?: String) => {
        if (res) {
            setSelectedDate(res)
        }
        setModel((oldData) => ({
            ...oldData,
            requestRefill: true
        }));
    }

    const openPayInfo = () => {

        setModel((oldData) => ({
            ...oldData,
            payRefillInfo: true
        }));
    }


    const handleOpen = () => {
        setModel((oldData) => ({
            ...oldData,
            message: true
        }));

    }

    const handleRequestRefillDetail = (order: Order) => {
        setOrderShippingAddress(order as Order);
        setModel((oldData) => ({
            ...oldData,
            requestRefillDetail: true
        }));

    }

    useEffect(() => {
        localStorage.setItem(
            "allergies", 
            OrderData?.allergies?.length 
              ? OrderData.allergies.map(item => item.name).join(', ') 
              : 'No Known Allergies'
          );
    },[OrderData])


      

    return (
        <>

            {model.payRefillInfo && (
                <BootstrapDialog onClose={() => handlePayClose()} open={model.payRefillInfo} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
                    <PayRefillInfo handleClose={(res?: boolean) => handlePayClose()} openPayInfo={openPayInfo} selectedDate={selectedDate} orderId={Number(orderId)} unEnrolledIds={unEnrolledIds} originalShippingAddress={originalShippingAddress} handleOpen={handleOpen} />
                </BootstrapDialog>
            )}

            {model.orderDate && (
                <BootstrapDialog onClose={() => handlePayClose()} open={model.orderDate} PaperProps={{ style: { height: 'auto', minHeight: 500, width: 360, overflowX: 'auto' } }}>
                    <OrderDeliveryDate handleClose={(res?: boolean) => handlePayClose()} orderId={Number(orderId)} handleOpenDeliveryAddress={(res?: String) => handleOpenDeliveryAddress(res)} />
                </BootstrapDialog>
            )}
            {model.message && (
                <BootstrapDialog onClose={() => handlePayClose()} open={model.message} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
                    <MessagePopup handleClose={(res?: boolean) => handlePayClose()} content='Your request has been sent in auto refill, please contact Customer Service for any changes.' />
                </BootstrapDialog>
            )}
            {model.requestRefill && (
                <BootstrapDialog onClose={() => handlePayClose()} open={model.requestRefill} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
                    <RequestRefill handleClose={(res?: boolean) => handlePayClose()} handleOpen={(res?: boolean) => handleOpenPay()} orderId={Number(orderId)} rxId={rxId} originalShippingAddress={originalShippingAddress} />
                </BootstrapDialog>
            )}
            {model.editAllergy && (
                <BootstrapDialog onClose={() => handlePayClose()} open={model.editAllergy} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
                    <EditAllergiesAsGuest handleClose={() => handlePayClose()} orderId={Number(orderId)} />
                </BootstrapDialog>
            )}
            {model.payInfo && (
                <BootstrapDialog onClose={() => handlePayClose()} open={model.payInfo} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
                    <PayInfo handleClose={() => handlePayClose()} handleTabClick={(res?: number) => props.handleTabClick(res)} payOrderData={payOrderData as OrderInterface} orderId={Number(orderId)} />
                </BootstrapDialog>
            )}
            {model.requestRefillDetail && (
                <BootstrapDialog onClose={() => handlePayClose()} open={model.requestRefillDetail} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
                    <RequestRefillDetail handleClose={(res?: boolean) => handlePayClose()} handleDeliveryDate={(res: Array<number>) => handleDeliveryDate(res)} orderId={Number(orderId)} />
                </BootstrapDialog>
            )}
            {/* {model.billingPopup && (
                <BootstrapDialog open={model.billingPopup} PaperProps={{ style: { minHeight: '60%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
                    <AddNewCard handleClose={handleBillingModelClose} skipButton={true} />
                </BootstrapDialog>
            )} */}
            {model.editAddress && (
                <BootstrapDialog onClose={() => handleEditOrderAddressClose()} open={model.editAddress} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
                    <EditGuestOrderShippingAddress handleClose={(res?: boolean) => handleEditOrderAddressClose(res)} orderId={Number(orderId)} />
                </BootstrapDialog>
            )}
            {model.updateDeliveryDate && (
                <BootstrapDialog onClose={() => handleUpdateOrderDeliveryDateClose()} open={model.updateDeliveryDate} PaperProps={{ style: { height: 'auto', minHeight: 500, width: 360, overflowX: 'auto' } }}>
                    <UpdateGuestOrderDeliveryDate handleClose={(res?: boolean) => handleUpdateOrderDeliveryDateClose(res)} orderId={Number(orderId)} orderDate={orderExpectedDate as Date} />
                </BootstrapDialog>
            )}

            {/*       {model.requestRefill && (
        <BootstrapDialog onClose={() => handleRequestRefillClose()} open={model.requestRefill} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
          <RequestRefill handleClose={(res?: boolean) => handleRequestRefillClose(res)} orderId={Number(orderId)} />
        </BootstrapDialog>
      )} */}
            <Grid className=""  >
                {/* <Header /> */}
                <Box className=" dashboard-page" position="relative" >
                    <Stack className='main-content-wrap2' >
                        {/* <Stack id="dashboard_set" direction={{ xs: 'column', sm: 'column', lg: 'row', xl: 'row' }} display="none">
                <Box flex={{ xs: 1 }} className="dashboard_col_layout">
                  <Stack direction={{ xs: 'column', md: 'row' }} className="dashboardFlex">
                    <Box padding={1}>
                      <Box className="card-sample-block">
                        <Stack className="patient_profile" direction={{ sm: 'column', lg: 'row' }} alignItems="center">
                          <Box>
                            <Box className="card-user-profile" style={user.getSmallImage ? { background: `url('${user?.getSmallImage}` } : {}}>
                              <label className="dashboard_camera" htmlFor="upload-button">
                                <img src={cameraPic} alt="camera" width={38} height={38} />
                              </label>
                              <input type="file" id="upload-button" style={{ display: 'none' }} onChange={handleImageChange} />
                            </Box>
                          </Box>
                          <Box className="card-user-desc">
                            <Typography className="heading-03-v1  heading-03-v1-size" variant="h3" component="h3">
                              Welcome, <br /> {`${user.firstName} ${user.middleName} ${user.lastName}`.trim()}
                            </Typography>
                            <Typography component="p" className="paragraph-03-v2">
                              <Typography className="small" style={{ marginTop: '7px', wordBreak: 'keep-all' }}>
                                Here’s what’s happening in your account!
                              </Typography>
                            </Typography>
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                    <Box padding={1} flex={{ xs: 1, md: 3 }} sx={{ overflowX: 'hidden' }}>
                      <Box className="card-sample-block">
                        <Box className="card_sample_in">
                          <Box className="patient-invoice-block">
                            <Box className="patient-invoice-heading">
                              <Box className="patient-invoice-heading-text">
                                <Typography variant="h4" component="h4" className="heading-04-v1" color="#263238CC">
                                  Unpaid Invoices
                                </Typography>
                              </Box>
                            </Box>
                            <Box className="patient-unpaid-container">
                              {unpaidOrders && unpaidOrders.length > 0 ? (
                                <Box className="top_tab_out signup_header">
                                  <AppBar position="static" className="tabs-bar">
                                    <Tabs
                                      className="unpaid_invoices_head"
                                      value={unpaidInvoiceTabIndex}
                                      onChange={handleUnpaidInvoiceTabChange}
                                      TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
                                      style={{ background: '#ffffff' }}
                                      indicatorColor="primary"
                                      textColor="inherit"
                                      variant="fullWidth"
                                    >
                                      {unpaidOrders &&
                                        unpaidOrders.map((item: Order, index: number) => {
                                          return <Tab className={unpaidInvoiceTabIndex === index ? 'tab-btn' : 'tab-btn-nonactive'} key={index} label={item?.order?.invoiceNumber} {...tabProps(index)} />;
                                        })}
                                    </Tabs>
                                    {unpaidOrders.map((item: Order, index: number) => (
                                      <>
                                        <TabPanel key={index} value={unpaidInvoiceTabIndex} index={index}>
                                          <Grid container xl={12} lg={12} md={12} xs={12} style={{ display: 'flex', flexDirection: 'column', overflowX: 'scroll', minHeight: '200px', maxHeight: '250px' }}>
                                            {Array.isArray(item?.prescriptions) &&
                                              item?.prescriptions.map((prescription) => (
                                                <Grid item xl={4} lg={4} md={4} xs={12} className="patient-medicine-container">
                                                  <Box className="medicine-image">
                                                    <img src="https://www.imprimisrx.com/s/sfsites/c/cms/delivery/media/MCSATDSFIJUBGT7OX7HPRPWKNACA" height={100} width={100} />
                                                  </Box>
                                                  <Box className="medicine-desc">
                                                    {item?.order?.showPrice && <Typography className="medicine-price">${prescription?.price}</Typography>}
                                                    <Typography className="medicine-name">{prescription?.name}</Typography>
                                                    <Typography className="prescriber-name">
                                                      Prescriber: <span>{`${item?.doctor?.firstName} ${item?.doctor?.lastName}`}</span>
                                                    </Typography>
                                                    <Typography className="issued-date">
                                                      Issued: <span>{moment.utc(item?.order?.date).format('MM-DD-YY')}</span>
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                              ))}
                                          </Grid>
                                          {item?.order?.isPayable && item?.order?.showPrice && (
                                            <Box className="add_btn_out" sx={{ mt: 2 }}>
                                              <Button className="add_btn" onClick={() => handleCurrentUnpaidInvoice(item)}>
                                                Pay Invoice
                                              </Button>
                                            </Box>
                                          )}
                                        </TabPanel>
                                      </>
                                    ))}
                                  </AppBar>
                                </Box>
                              ) : (
                                <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
                                  <Typography className="no-invoice-message">No invoice available for payment</Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              </Stack> */}
                        <Grid container spacing={2} display={'flex'} justifyContent={'flex-start'} alignItems={'center'} paddingRight={3}>
                            <Grid item xs={4} sm={4} md={3} lg={3} mt={2} pb={2}>
                                <Stack className="add_btnouter" direction="row" justifyContent="flex-start" alignItems="center" pl="12px" gap={1.5}>


                                    {/* <Box className="add_outerbtns bt-flex"  >
                      <PrimaryButton label={'Back'}
                        onClick={logoutHandler}
                      />

                    </Box> */}
                                    <Box className=" ">
                                        <IconButton onClick={logoutHandler} style={{ background: "#00acba", color: "#fff" }}>
                                            <ArrowBack />
                                        </IconButton>
                                    </Box>
                                </Stack>
                            </Grid> </Grid>
                        <Box >
                            <Box className="card-sample-block">
                                <Box className="card_sample_in">
                                    <Box className="patient-order-block">
                                        {/*    <Box className="patient-order-heading">
                                            <Box className="patient-order-heading-text" style={{ textAlign: "center" }}>
                                                <Typography variant="h4" component="h4" className="heading-04-v1" color="#263238CC">
                                                     Pending Prescriptions
                                                </Typography>
                                            </Box>
                                        </Box> */}
                                        <Grid xs={12} md={12} lg={12} sx={{ margin: 'auto', }} className="patient-unpaid-container">
                                            {OrderData && OrderData.orders.length > 0 ? (
                                                <>
                                                    <Grid item xl={12} lg={12} md={12} xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', overflowX: 'auto', }}>
                                                        <TableContainer component={Paper} className="table_customized_Prescription2">
                                                            <Table stickyHeader>
                                                                <TableHead className="table_head">
                                                                    <TableRow>
                                                                        <StyledTableCell>Orders </StyledTableCell>
                                                                        <StyledTableCell></StyledTableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody className="table_body">

                                                                    {
                                                                        OrderData.orders?.map((order: any, index: any) => (
                                                                            <StyledTableRow key={index}>
                                                                                <StyledTableCell component="td">
                                                                                    <Stack spacing={1}>
                                                                                        <FormControlLabel value={order.order.invoiceNumber} control={<Radio checked={order.order.invoiceNumber == enrollmentStates ? true : false} value={order.order.invoiceNumber} onClick={() => enrollHandler(order as OrderInterface)} />} label={<>
                                                                                            {order.order.invoiceNumber}

                                                                                        </>} />



                                                                                    </Stack>
                                                                                </StyledTableCell>

                                                                            </StyledTableRow>
                                                                        )
                                                                        )}

                                                                    {enrollmentStates && (<Box style={{ fontSize: 20, fontWeight: 600 }} pt={2}>Prescriber: </Box>)}
                                                                    {
                                                                        OrderData.orders?.map((order: any, index: any) => (
                                                                            <>
                                                                                <Typography className="prescriber-name">
                                                                                    {order.order.invoiceNumber == enrollmentStates && (<StyledTableRow key={index} style={{ width: "100%" }}>

                                                                                        <StyledTableCell component="td">
                                                                                            <Stack style={{ lineBreak: "auto", whiteSpace: "normal", display: "inline-block" }}>{`${order.doctor.lastName} ${order.doctor.firstName}`}<br />

                                                                                            </Stack>



                                                                                        </StyledTableCell>
                                                                                    </StyledTableRow>)}
                                                                                </Typography></>
                                                                        ))}


                                                                    {enrollmentStates && (<Box style={{ fontSize: 20, fontWeight: 600 }} pt={2}>Prescriptions: </Box>)}
                                                                    {
                                                                        OrderData.orders?.map((order: any, index: any) => (
                                                                            <>
                                                                                <Typography className="prescriber-name">
                                                                                    {order.order.invoiceNumber == enrollmentStates && order.prescriptions?.map((prescription: any, index: any) => (
                                                                                        <StyledTableRow key={index} style={{ width: "100%" }}>

                                                                                            <StyledTableCell component="td">
                                                                                                <Stack style={{ lineBreak: "auto", whiteSpace: "normal", display: "inline-block" }}>{`${index + 1}. ${prescription.name}`}<br />

                                                                                                </Stack>



                                                                                            </StyledTableCell>
                                                                                            {/* {order.prescriptions.some((item:any) => item.refillLeft >= "1") &&
                                                                                                <StyledTableCell component="td">
                                                                                                    <Grid item className="Enroll-box-container" xs={10} md={10} xl={10} lg={10}>

                                                                                                        <Button
                                                                                                            className={`request-right-box-btn-2 ${unEnrollmentStates[order.order.invoiceNumber] ? 'enrolled' : ''}`}
                                                                                                            onClick={() => handleRequestRefillDetail(order)}
                                                                                                            style={{ background: unEnrollmentStates[order.order.invoiceNumber] ? "#f3893d" : "", color: unEnrollmentStates[order.order.invoiceNumber] ? "#fff" : "#f3893d" }}
                                                                                                        >
                                                                                                            <Stack style={{ textTransform: "none" }}>Request Refill</Stack>                                                                            </Button>


                                                                                                    </Grid>
                                                                                                </StyledTableCell>} */}

                                                                                            <StyledTableCell>
                                                                                                <Grid item className="Enroll-box-container" xs={10} md={10} xl={10} lg={10}>
                                                                                                    {/*  {unEnrollmentStates[prescription.rxId] ? <CheckIcon style={{
                                                                                position: "absolute", color: "#fff", zIndex: 9,
                                                                                marginTop: 10,
                                                                                marginRight: 8,
                                                                                marginLeft: 10,
                                                                                fontWeight:600,
                                                                                borderRadius: 50
                                                                            }} /> : ""} */}
                                                                                                    <Button
                                                                                                        className={`request-right-box-btn-2 ${unEnrollmentStates[prescription.rxId] ? 'enrolled' : ''}`}
                                                                                                        onClick={() => unEnrollHandler(prescription.rxId)}
                                                                                                        style={{ background: unEnrollmentStates[prescription.rxId] ? "#f3893d" : "", color: unEnrollmentStates[prescription.rxId] ? "#fff" : "#f3893d" }}
                                                                                                    >
                                                                                                        <Stack style={{ position: 'absolute', fontSize: 8, marginTop: -19, textTransform: "none" }}> {unEnrollmentStates[prescription.rxId] ? "Selected for" : "Click here to"}</Stack>
                                                                                                        <Stack style={{ paddingTop: 11, textTransform: "none" }}>Auto Refill</Stack>                                                                            </Button>
                                                                                                </Grid>
                                                                                            </StyledTableCell>
                                                                                        </StyledTableRow>

                                                                                    ))}
                                                                                </Typography>

                                                                                {order.order.invoiceNumber == enrollmentStates && (
                                                                                    <>
                                                                                        <Grid display={"flex"} justifyContent={"space-between"} pt={2} pb={1} >
                                                                                            <Stack className="address-text-container">
                                                                                                <Typography className="address-heading" lineHeight={1.6}> Shipping Address: </Typography>
                                                                                                <Typography className="address-text" variant="body1" lineHeight={1.6} >
                                                                                                    <span className="address-row">{`${order?.shippingAddress?.address1 || ''} ${order?.shippingAddress?.address2 || ''}`.trim()}</span>
                                                                                                    <br></br>
                                                                                                    <span className="address-row">{`${order?.shippingAddress?.city || ''} ${order?.shippingAddress?.state || ''}`.trim()}</span>
                                                                                                    <br></br>
                                                                                                    <span className="address-row">{`${order?.shippingAddress?.zipCode || ''}`.trim()}</span>&nbsp;&nbsp;
                                                                                                    <span className="edit_icon" onClick={() => handleEditOrderAddress(order)}>
                                                                                                        <img src={Edit} alt="Imprimis RX A Harrow Company" />
                                                                                                    </span>
                                                                                                </Typography>

                                                                                            </Stack>
                                                                                            <Stack className="delivery-date-container" direction={'row'} sx={{ display: { sm: 'grid', md: 'flex' } }}>
                                                                                                <Typography className="delivery-date-heading"> Expected By:&nbsp; </Typography>
                                                                                                <Typography className="delivery-date">{formattedExpectedDate(order)}&nbsp;</Typography>

                                                                                                <span className="edit_icon" onClick={() => handleUpdateOrderDeliveryDate(order)}>
                                                                                                    <img src={Edit} alt="Imprimis RX A Harrow Company" />
                                                                                                </span>

                                                                                            </Stack>

                                                                                        </Grid >
                                                                                        <Grid display={'flex'} alignItems={'center'} pt={2} pb={2}>
                                                                                            <Typography className="delivery-date-heading">
                                                                                                Allergies:&nbsp; {OrderData?.allergies.map(item => item.name).join(', ')}
                                                                                            </Typography>
                                                                                            <span className="edit_icon" onClick={() => handleEditAllergy(order)}>
                                                                                                <img src={Edit} alt="Imprimis RX A Harrow Company" />
                                                                                            </span>

                                                                                        </Grid>

                                                                                    </>
                                                                                )}

                                                                            </>
                                                                        )
                                                                        )}

                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        {enrollmentStates && (


                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <Divider />
                                                                <Grid item xs={12} sm={12} md={12} lg={12} mt={2} display={'flex'} sx={{ display: { xs: "inline-grid", sm: "flex" } }} justifyContent={'space-between'} alignItems={'center'} >
                                                                    <Grid item xs={6} sm={6} md={6} lg={6} display={'flex'} justifyContent={"flex-start"} alignItems={'center'}>

                                                                        <Stack display={'flex'} justifyContent={"flex-start"} alignItems={'center'}>
                                                                            <Box style={{ color: "#f3893d", fontSize: 25, fontWeight: 600, textAlign: "center" }} pl={2} display={'flex'} justifyContent={"flex-start"} alignItems={'center'}>Total Price: ${totalPrice}</Box>
                                                                        </Stack>
                                                                    </Grid>
                                                                    <Grid item xs={6} sm={6} md={12} lg={12} display={'flex'} justifyContent={"flex-end"} alignItems={'center'}>
                                                                        <Stack className="add_btnouter" direction="row" justifyContent="flex-end" alignItems="center" >


                                                                            <Box className="add_outerbtns bt-flex" display={'flex'} justifyContent={"flex-end"} alignItems={'center'} style={{ paddingTop: 10 }} pr={2} >
                                                                                <PrimaryButton label={'Confirm and Pay'}

                                                                                    onClick={() => handelPayModel()} />

                                                                            </Box>
                                                                        </Stack>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>)}

                                                    </Grid>

                                                </>

                                            ) : (
                                                <Box sx={{ display: 'flex', justifyContent: 'center', height: '50vh', width: '100%' }}>
                                                    <Typography className="no-orders-message">We found no prescription ready for the payment for you.</Typography>
                                                </Box>
                                            )}
                                        </Grid>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {/* <Box padding={1}>
                              <Box className="card-sample-block">
                              <Box style={{ display: 'flex', paddingTop: '10px', alignItems: 'flex-end' }}>
                                <Box style={{ flex: 1 }}>
                               <Typography variant="h4" textAlign="center" component="h4" className="heading-04-v1" color="#263238CC">
                                  Most Recent Orders
                               </Typography>
                          </Box>
                    <Box>
                      <Stack onClick={redirectToPrescriptions} style={{ cursor: 'pointer' }}>
                        <Box className="view_all_info">View All</Box>
                      </Stack>
                    </Box>
                  </Box>
                  <Box className="recent-order-table-layout">
                    {user ? (
                      orders && <OrderTable data={orders} />
                    ) : (
                      <Box className="most_recent_prescription_container">
                        <Stack mt={2} pt={4} pb={4} textAlign="center">
                          <Typography className="important_message">
                            <b>Important:</b> Here you will be able to view and sort your most recent orders{' '}
                          </Typography>
                        </Stack>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box> */}
                    </Stack>
                </Box >

                <Box className="chat-floating-icon">
                    <img src={Chat} alt="logo" height={50} width={50} />
                </Box>
                {/* <Box className="Prescribe-icon" onClick={handlePrescribeDetail}>
          <img src={Prescribe} alt="logo" height={100} width={180} />
        </Box> */}
            </Grid >
            {/* <Footer /> */}
        </>
    )
}


/* 
<TableBody className="table_body">

{
    orders && orders.length > 0 && (
        orders.some((order: any) => order.order.isPayable) ? (
            orders.map((order: any, index: any) => (
                order.order.isPayable && (
                    <StyledTableRow key={index}>
                        <StyledTableCell component="td">
                            <Stack spacing={1}>
                                <FormControlLabel
                                    value={order.order.invoiceNumber}
                                    control={
                                        <Radio
                                            checked={order.order.invoiceNumber === enrollmentStates}
                                            value={order.order.invoiceNumber}
                                            onClick={() => enrollHandler(order)}
                                        />
                                    }
                                    label={<>{order.order.invoiceNumber}</>}
                                />
                            </Stack>
                        </StyledTableCell>
                    </StyledTableRow>
                )
            ))
        ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', height: '50vh', width: '100%' }}>
            <Typography className="no-orders-message">No Pending Prescriptions</Typography>
        </Box>
        )
    ) 
}

{
    orders?.map((order: any, index: any) => (
        <>
            <Typography className="prescriber-name">
                {order.order.invoiceNumber == enrollmentStates && order.prescriptions?.map((prescription: any, index: any) => (
                    <StyledTableRow key={index}>

                        <StyledTableCell component="td">
                            <Stack style={{ lineBreak: "auto", whiteSpace: "normal", display: "inline-block" }}>{`${index + 1}: ${prescription.name}`}<br />

                            </Stack>
                        </StyledTableCell>
                    </StyledTableRow>

                ))}
            </Typography>

        </>
    )
    )}
{enrollmentStates && (<Grid container spacing={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
    <Grid item xs={12} sm={12} md={12} lg={12} mt={2} display={'flex'} justifyContent={'space-between'} >
        <Grid item xs={6} sm={6} md={6} lg={6}>

            <Stack >
                <Box style={{ color: "#f3893d", fontSize: 25, fontWeight: 600 }}>${totalPrice}</Box>
            </Stack>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
            <Stack className="add_btnouter" direction="row" justifyContent="flex-end" alignItems="center" gap={1.5}>


                <Box className="add_outerbtns bt-flex"  >
                    <PrimaryButton label={'Pay'}

                        onClick={() => handelPayModel()} />

                </Box>
            </Stack>
        </Grid>
    </Grid>
</Grid>)}
</TableBody> */