import { Box, Button, Container, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material';
import { FieldControl, FieldGroup } from 'react-reactive-form';
import { cityOptions, stateOptions } from '../../../services/components/selectOptions.service';

import CloseIcon from '@mui/icons-material/Close';
import { DialogTitleProps } from '../../../interfaces/DialogTitleProps';
import Home from '../../../assets/icons/shipping_home.svg';
import { InputSelect } from '../../../core/forms/inputs/InputSelect';
import { InputText } from '../../../core/forms/inputs/InputText';
import { InputTextArea } from '../../../core/forms/inputs/InputTextArea';
import { addNewShippingAddressForm, EditGuestOrderShippingAddressFormHandler, EditOrderShippingAddressFormHandler } from '../../../services/pages/popup-form/AddNewShippingAddressForm.service';
import { InputAddress } from '../../../core/forms/inputs/InputAddress';
import { AxiosResponse } from 'axios';
import { AllergyAutoCompleteInput } from '../../../core/forms/inputs/AllergyAutoCompleteInput';
import { allergiesForm, AllergiesUpdateHandler } from '../../../services/pages/settings/personalLoginForm';
import { toast } from 'react-toastify';
import { formatMessage } from '../../../constants/util';

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export const EditAllergiesAsGuest = (props: { handleClose: (res?: boolean) => void; orderId: number }) => {


    let orders = JSON.parse(localStorage.getItem('Orders') || '[]');


    const onSelectAllergies = (data: { value: string }[]) => {
        const value = data.map((item) => item).join();
        allergiesForm.patchValue({
            allergies: value
        });
    };

    const settingPersonalInfoHandler = async (e?: React.SyntheticEvent) => {
        // const res: any = await AllergiesUpdateHandler(e as React.SyntheticEvent, props.orderId);
        // if (res?.status === 201) {
        //     //   dispatch(profileActions.setUserData({ user: User.create(res.data, true) as User }));
        //     // navigate('/home/settings');
        // }
        // Mark the allergies form as touched
        allergiesForm.controls.allergies.markAsTouched({ emitEvent: true, onlySelf: true });

        // Split allergies from the form value or default to 'No Known Allergies'
        let newAllergies = allergiesForm.value.allergies
            ? allergiesForm.value.allergies.split(',').map((item: string) => item.trim())
            : [];

 

        // Get orders from local storage
        let localData = JSON.parse(localStorage.getItem('Orders') || '[]');


        let currentAllergyNames = localData?.allergies.map((allergy: any) => allergy.name) || [];

        // Filter out any existing allergies that are not in the new allergy list (i.e., delete them)
        let remainingAllergies = localData.allergies.filter((allergy: any) =>
            newAllergies.includes(allergy.name)
        );

        // Add new unique allergies that are not already in local storage
        let uniqueNewAllergies = newAllergies.filter((newAllergy: string) =>
            !currentAllergyNames.includes(newAllergy)
        );

        localStorage.setItem("allergies", currentAllergyNames)

        // Copy all fields and add unique new allergy objects with new names
        let updatedAllergies = [
            ...remainingAllergies, // Keep only those that are in the new allergy list
            ...uniqueNewAllergies.map((newAllergy: string) => ({
                ...localData.allergies[0], // Copy structure from an existing allergy
                name: newAllergy // Add new allergy names
            }))
        ];



        // Update the allergies field in the found order
        localData.allergies = updatedAllergies;

        // Save the updated data back to local storage
        localStorage.setItem('Orders', JSON.stringify(localData));

        // Notify the user
        toast(formatMessage('Allergies updated successfully'));




        props.handleClose(false)
    };


    const allergiesArray = orders?.allergies?.length
        ? orders.allergies.map((item: { name: string }) => item.name.trim())
        : [];

    console.log(orders?.allergies, allergiesArray);


    return (
        <>
            <DialogContent dividers className="popup_content">
                <Box component="main" className="card-info">
                    <Container maxWidth="lg">
                        <Box className="main-box" mt={4}>
                            <Box sx={{ bgcolor: 'background.paper' }}>
                                <Stack className="modal_heading_main" direction="row" justifyContent="center" alignItems="center" mt={3}>
                                    <Typography className="heading_bottom_without_border">
                                        <span className="profile_icon">
                                            <img src={Home} alt="Imprimis RX A Harrow Company" width={16} />
                                        </span>
                                        Edit Allergies
                                    </Typography>
                                </Stack>
                                <Box>
                                    <Grid display={'flex'} alignItems={'center'} pt={2} pb={2}>

                                    </Grid>
                                    <FieldGroup
                                        control={addNewShippingAddressForm}
                                        render={() => (
                                            <form>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={12} lg={12}>
                                                        <FieldControl
                                                            name="allergies"
                                                            render={AllergyAutoCompleteInput}
                                                            meta={{
                                                                value: 'allergies',
                                                                name: 'allergies',
                                                                label: 'Allergies',
                                                                placeholder: 'Please Enter Allergies',
                                                                selectedAllergyOptions: allergiesArray,
                                                                onChange: onSelectAllergies,
                                                            }}
                                                        />                          </Grid>




                                                    <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                                                        <Stack className="add_btnouter" direction="row" justifyContent="center" alignItems="center" gap={1.5}>
                                                            <Box className="add_outerbtns">
                                                                <Button
                                                                    className="cancel_btn"
                                                                    variant="contained"
                                                                    style={{
                                                                        fontSize: '16px',
                                                                        fontWeight: '600',
                                                                        backgroundColor: '#00ACBA',
                                                                        border: '1px solid #00ACBA',
                                                                        borderRadius: '8px',
                                                                        boxShadow: 'none',
                                                                        textTransform: 'capitalize',
                                                                        flex: 1,
                                                                        width: '100%',
                                                                        height: '49px'
                                                                    }}
                                                                    onClick={settingPersonalInfoHandler}
                                                                >
                                                                    Save
                                                                </Button>
                                                            </Box>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            </DialogContent>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => props.handleClose(false)}></BootstrapDialogTitle>
        </>
    );
};
