import React, { useState } from 'react';
import { Box, FormControlLabel, Grid, Stack, Typography, FormControl, TextField, Avatar, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FieldControl, FieldGroup } from 'react-reactive-form';
import { NavLink, useNavigate } from 'react-router-dom';
import { cityOptions, notificationOptions, stateOptions } from '../../services/components/selectOptions.service';
import { GuestCheckOutTwoHandler, PatientSignUpThreeForm, PatientSignUpOneHandler } from '../../services/auth/register.service';
import { useAppDispatch, useAppSelector } from '../../store';

import { AxiosResponse } from 'axios';
import { CheckInput } from '../../core/forms/inputs/CheckInput';
import { GoogleAutoCompleteInput } from '../../core/forms/inputs/GoogleAutoCompleteInput';
import { InputAddress } from '../../core/forms/inputs/InputAddress';
import { InputEmail } from '../../core/forms/InputEmail';
import InputPassword from '../../core/forms/inputs/InputPassword';
import { InputPhone } from '../../core/forms/inputs/InputPhone';
import { InputSelect } from '../../core/forms/inputs/InputSelect';
import { InputText } from '../../core/forms/inputs/InputText';
import Patient from '../../constants/grx-api';
import PrimaryButton from '../../core/buttons/primary-button';
import TertiaryButton from '../../core/buttons/tertiary-button';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { User } from '../../models/User';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormArray, FormControl as FormControlType, FormGroup, Validators } from 'react-reactive-form';
import { Color } from '../../interfaces/Color';
import moment from 'moment';
import { RadioInput } from '../../core/forms/inputs/RadioInput';
import { AllergyAutoCompleteInput } from '../../core/forms/inputs/AllergyAutoCompleteInput';
import { InputTextCC } from '../../core/forms/InputTextCC';
import { InputExpiryDate } from '../../core/forms/InputExpiryDate';
import { InputCvv } from '../../core/forms/InputCvv';
import { InputZipCode } from '../../core/forms/inputs/InputZipCode';
import { GET_ALLERGIES } from '../../constants/Endpoints';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { genderOptions } from '../../services/components/selectOptions.service';
import { profileActions } from '../../store/Actions';
import PatientWithoutToken from '../../constants/grx-api-tokenless';
import { InputDate } from '../../core/forms/inputDate';
import { InputDateOfBirth } from '../../core/forms/inputs/InputDateOfBirth';
import SujaImg from '../../assets/suja.png';
import axios from 'axios';
import { debounce } from '@mui/material/utils';
import guestSignup from './guest-signup'
import GuestSignup from './guest-signup';
import DashboardPage from './order-payment';
 
import { formatMessage } from '../../constants/util';


interface CustomTooltipProps extends TooltipProps {
  open?: boolean;
}

const CustomWidthTooltip = styled(({ className, ...props }: CustomTooltipProps) => <Tooltip {...props} classes={{ popper: className }} open={true} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500
  }
});

const Index = React.memo(() => {
  const router = useNavigate();
  const [allergyOptions, setAllergyOptions] = useState<any>([]);

  const patientFormSubmit = async () => {
    console.log("hii3")

    await GuestCheckOutTwoHandler(router);
  };

  const onDateChange = (date: Date) => {
    console.log('Date Picker Date', moment.utc(date));
    PatientSignUpThreeForm.patchValue({
      dateOfBirth: date
    });
    // if (e && e.$d && e.$d !== null) {
    //   PatientSignUpThreeForm.patchValue({
    //     dateOfBirth: e.$d
    //   });
    // } else {
    //   PatientSignUpThreeForm.patchValue({
    //     dateOfBirth: ''
    //   });
    // }
  };

  const tab = localStorage.getItem("tab")

  useEffect(() => {
    const tabvalue = tab?tab:0
    setActiveTab(Number(tabvalue));
  },[tab])


  const orderId = 0

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (res?: number) => {
    setActiveTab(res ?? 0); // Default to 0 if res is undefined
  };

  console.log(activeTab === 1)

  // update form validation
  let isValidationUpdateInProgress = false;

  const updateValidation = () => {
    if (isValidationUpdateInProgress) {
      return;
    }

    isValidationUpdateInProgress = true;
  }

  // update allergy validation


  const logoutHandler = async () => {
    try {
       localStorage.removeItem('Orders');
     localStorage.removeItem('tab');
      localStorage.clear();
      //  window.location.reload();
      router('/');
    } catch (err) {
      console.error(err);
      toast(formatMessage('Unable to remove Login details from device storage'));
    }
  };


 
  return (
    <Box sx={{ height: 'auto' }}>
      <>
        <Stack className="avtar-container">
          <Box className="avtar-container-warp">
            <Avatar sx={{ width: 56, height: 56 }} alt="Remy Sharp" src={SujaImg} />
            <Box sx={{ width: '100%' }}>
              <Typography className="dr-name">Dr. Suja Alum, PharmD, M.S., M.B.A.</Typography>
              <Typography className="avtar-message">
                Hello, I’m Suja Alum, the <span>pharmacist-in-charge</span> at ImprimisRx. In order to begin processing your prescription, please provide the information below. If you have any questions, please do not hesitate to contact our Customer Service team by using the <strong>chat</strong>{' '}
                feature at the bottom right of this page, or Text us at{' '}
                <a href="tel:8582642082">
                  <strong>858-264-2082</strong>
                </a>
                {/* , or email us at{' '}
              <a href="mailto:info@imprimisrx.com">
                <strong>info@imprimisrx.com</strong>
              </a> */}
                .
              </Typography>
            </Box>
          </Box>
        </Stack>
        <Stack className="about">Guest Checkout</Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={4} mt={4} className="hid-tab">
            <Typography
              className={`info ${activeTab === 0 ? 'active maininfo' : ''}`}
              style={{ padding: 10 }}
         
            >
              Patient Info
            
              {activeTab === 0 && (
                <div className="info-small"></div>
              )}
            </Typography>


            <Typography
              className={`info ${activeTab === 1 ? 'active maininfo' : ''}`}
            
              style={{ padding: 10 }}

            >
              Payment
              {activeTab === 1 && (
                <div className="info-small"></div>
              )}
            </Typography>


            <Typography
              className={`info ${activeTab === 2 ? 'active maininfo' : ''}`}
              
              style={{ padding: 10 }}

            >
              Confirmation
              {activeTab === 2 && (
                <div className="info-small"></div>
              )}
            </Typography>

          </Grid>

          <Grid item xs={12} md={8} lg={8} className='maininfo2'>
            {activeTab === 0 && (

              <GuestSignup handleTabClick={(res?: number) => handleTabClick(res)} />

            )}

            {activeTab === 1 && <DashboardPage handleTabClick={(res?: number) => handleTabClick(res)} />}
            {activeTab === 2 && <Grid><div style={{ minHeight: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}><p style={{ fontSize: 20, fontWeight: 500, display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", padding: 30 }}>"Thank you for your payment. Your transaction will be processed successfully by our team."






            </p></div><Grid container spacing={2} display={'flex'} justifyContent={'center'} alignItems={'center'} paddingRight={3}>
                <Grid item xs={4} sm={4} md={4} lg={4} mt={2} pb={2}>
                  <Stack className="add_btnouter" direction="row" justifyContent="center" alignItems="center" gap={1.5}>


                    <Box className="add_outerbtns bt-flex"  >
                      <PrimaryButton label={'Return'}
                        onClick={logoutHandler}
                      />


                    </Box>
                  </Stack>
                </Grid> </Grid>
            </Grid>}
          </Grid>
        </Grid>


      </>
    </Box>
  );
});

export default Index;
